import { Box, Typography } from "@mui/material";
import React, { useEffect, useContext } from "react";

import { useSelector } from "react-redux";
import { SocketContext } from "../../context/socket";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const profile = useSelector((store) => store.profile);

  const socket = useContext(SocketContext);

  return (
    <React.Fragment>
      <Box className={mode ? "dark-bg-1" : "light-bg-1"} p={1}>
        <Typography variant="body2" color="secondary">
          Durgarao accepted your request
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default Index;
