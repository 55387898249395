import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";
import Loader from "../components/Loader";

function CompanyProtectedRoute({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();
  if (profile?.success == true) {
    if (profile?.data?.company == null) {
      return <Navigate to="/startup-create/startup-details" />;
    } else if (
      profile?.data?.company?.applicationStage == "submitted" ||
      profile?.data?.company?.applicationStage == "rejected"
    ) {
      return (
        <Navigate
          to={`/application-status/company/${profile?.data?.company?._id}`}
        />
      );
    } else {
      return children;
    }
  } else {
    return <Loader message="Please wait!!!" />;
  }
}

export default CompanyProtectedRoute;
