import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "notes";
const initialState = {
  notes: [],
  loading: false,
  error: null,
};

export const fetchNotes = createAsyncThunk("fetchNotes", async () => {
  const data = await axiosApi.get(`${process.env.REACT_APP_BASE_URL}note/`);
  return data.data;
});

const fetchNotesSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.notes = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      state.notes = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchNotes.rejected, (state, action) => {
      state.notes = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default fetchNotesSlice.reducer;
