import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Typography, Stack, Button, Box } from "@mui/material";

const sliderVariant = {
  initial: {
    x: "100vw",
  },
  final: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  exit: {
    x: "500vw",
    duration: 2,
  },
};

const sliderContainerVariant = {
  initial: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  final: {
    backgroundColor: "rgba(0,0,0,0.4)",
    transition: {
      delay: 0.3,
    },
  },
  exit: {
    backgroundColor: "rgba(0,0,0,0)",
    duration: 2,
    delay: 1,
  },
};

const Slider = ({ displayNone, reveal, closeSlider }) => {
  const location = useLocation();
  const getNavColor = () => {
    if (
      location.pathname == "/landingpage" ||
      location.pathname == "/" ||
      location.pathname == "/landingpage/"
    ) {
      return "#1F212D";
    } else {
      return "#1F212D";
    }
  };
  return (
    <AnimatePresence>
      {reveal && (
        <motion.div
          className="slider"
          style={sliderContainerStyle}
          onClick={(e) => displayNone(e)}
          variants={sliderContainerVariant}
          initial="initial"
          animate="final"
          exit="exit"
        >
          <AnimatePresence>
            {reveal && (
              <motion.div
                style={sliderStyle}
                variants={sliderVariant}
                exit="exit"
              >
                <Stack direction="column">
                  <Stack
                    direction="row"
                    justifyContent={"flex-end"}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <button
                      style={crossStyling}
                      className="cancelSlider"
                      onClick={(e) => closeSlider()}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          cursor: "pointer",
                        }}
                        fontSize="25px"
                      >
                        <CloseIcon />
                      </Typography>
                    </button>
                  </Stack>
                  <Stack
                    direction="column"
                    sx={{
                      pl: "70px",
                      pr: "200px",
                    }}
                  >
                    <Link to="/landingpage">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname == `/landingpage`
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          Home
                        </span>
                      </Button>
                    </Link>
                    <Link to="/landingpage/startup">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname.includes(`/landingpage/startup`)
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          Startups
                        </span>
                      </Button>
                    </Link>
                    <Link to="/landingpage/investor">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname.includes(
                                `/landingpage/investor`
                              )
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          Investors
                        </span>
                      </Button>
                    </Link>
                    <Link to="/landingpage/mentor">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname.includes(`/landingpage/mentor`)
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          Mentors
                        </span>
                      </Button>
                    </Link>
                    <Link to="/landingpage/expert">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname.includes(`/landingpage/expert`)
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          Experts
                        </span>
                      </Button>
                    </Link>
                    <Link to="/landingpage/partnership">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname.includes(
                                `/landingpage/partnership`
                              )
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          Partners
                        </span>
                      </Button>
                    </Link>
                    <Link to="/aboutus">
                      <Button
                        variant="text"
                        sx={{
                          color: getNavColor(),
                          fontWeight: "600",
                          fontSize: "30px",
                        }}
                        onClick={closeSlider}
                      >
                        <span
                          style={{
                            borderBottom: `${
                              location.pathname.includes(`/aboutus`)
                                ? "2px solid #eb4d89"
                                : "none"
                            }`,
                          }}
                        >
                          About Us
                        </span>
                      </Button>
                    </Link>

                    {!localStorage.getItem("token") ? (
                      <React.Fragment>
                        <Link to="/signin">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={closeSlider}
                          >
                            Login
                          </Button>
                        </Link>
                        <Link to="/register">
                          <Button variant="contained" onClick={closeSlider}>
                            Get Started
                          </Button>
                        </Link>
                      </React.Fragment>
                    ) : (
                      <Link to="/">
                        <Button
                          variant="outlined"
                          sx={{
                            ml: 1,
                            mt: 2,
                            borderColor: "#fff",
                            color: "#fff",
                          }}
                          onClick={closeSlider}
                        >
                          Go to Home
                        </Button>
                      </Link>
                    )}
                  </Stack>
                </Stack>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const sliderStyle = {
  background: "#ACFF8E",
  height: "100%",
  float: "right",
};

const sliderContainerStyle = {
  position: "fixed",
  height: "100vh",
  zIndex: "100000",
  width: "100%",
};

const crossStyling = {
  float: "right",
  marginRight: "30px",
  marginTop: "20px",
  background: "none",
  border: "none",
  outline: "none",
};

export default Slider;
