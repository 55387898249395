import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "bookmarksJob";
const initialState = {
  bookmarksJob: [],
  loading: false,
  error: null,
};

export const bookmarksJobAPI = createAsyncThunk(
  "bookmarks/job",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/jobs?skip=${skip}&limit=${limit}&status=active${
        search != "" ? `&search=${search}` : ""
      }`
    );
    return data.data;
  }
);

const bookmarksJobSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksJobAPI.pending, (state) => {
      state.bookmarksJob = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(bookmarksJobAPI.fulfilled, (state, action) => {
      state.bookmarksJob = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bookmarksJobAPI.rejected, (state, action) => {
      state.bookmarksJob = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default bookmarksJobSlice.reducer;
