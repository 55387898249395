/* eslint-disable import/no-anonymous-default-export */
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export let darkTheme = createTheme({
  shape: {
    borderRadius: 0, // defaults to 4
  },
  components: {
    //disableRipple effect (water bubble effect) for all buttons
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      },
    },
  },

  typography: {
    fontFamily: "Inter",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#1B213E",
    },
    color: "red",

    bgColor: "#262736",

    primary: {
      main: "#ACFF8E",
      light: "#ACFF8E",
      dark: "#ACFF8E",
      contrastText: "#1B213E",
    },
    secondary: {
      main: "#EEEEEE",
      light: "#EEEEEE",
      dark: "#262736",
      contrastText: "#ACFF8E",
    },
    error: {
      main: "red",
      light: "#1C1B26",
      dark: "#1C1B26",
      contrastText: "#EEEEEE",
    },
    info: {
      main: "#1C1B26",
      light: "#1C1B26",
      dark: "#1C1B26",
      contrastText: "#EEEEEE",
    },
    warning: {
      main: "#4E526B",
      light: "#4E526B",
      dark: "#4E526B",
      contrastText: "#EEEEEE",
    },
  },
});

darkTheme = responsiveFontSizes(darkTheme);

export let lightTheme = createTheme({
  components: {
    //disableRipple effect (water bubble effect) for all buttons
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      },
    },
  },

  typography: {
    fontFamily: "Inter",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "###F3F3F3",
    },
    color: "red",

    bgColor: "###F3F3F3",

    primary: {
      main: "#ACFF8E",
      light: "#ACFF8E",
      dark: "#ACFF8E",
      contrastText: "#F8F8F8",
    },
    secondary: {
      main: "#090F31",
      light: "#090F31",
      dark: "#090F31",
      contrastText: "#4E4D4D",
    },
    error: {
      main: "red",
      light: "#1C1B26",
      dark: "#1C1B26",
      contrastText: "#EEEEEE",
    },
    info: {
      main: "#1C1B26",
      light: "#1C1B26",
      dark: "#1C1B26",
      contrastText: "#EEEEEE",
    },
    warning: {
      main: "#4E526B",
      light: "#4E526B",
      dark: "#4E526B",
      contrastText: "#EEEEEE",
    },
  },
});

lightTheme = responsiveFontSizes(lightTheme);

export default { darkTheme: darkTheme, lightTheme: lightTheme };
