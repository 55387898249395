import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AppBar, Stack, Typography } from "@mui/material";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { useSelector } from "react-redux";

export default function BottomNav() {
  const mode = useSelector((store) => store.mode.mode);
  const currentProfile = useSelector((store) => store.switch.profile);

  const location = useLocation();
  const tabs = [
    {
      id: 1,
      name: "Explore",
      path:
        localStorage.getItem("profile") == "Startup"
          ? "/search/startup"
          : "/search/investor",
      icon: <ExploreOutlinedIcon />,
    },
    {
      id: 2,
      name: "Dashboard",
      path: "/dashboard/received",
      icon: <GridViewOutlinedIcon />,
    },
    {
      id: 3,
      name: "Messages",
      path: "/inbox",
      icon: <EmailOutlinedIcon />,
    },
    {
      id: 4,
      name: "Profile",
      path: "/settings",
      icon: <AccountCircleOutlinedIcon />,
    },
  ];
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ top: "auto", bottom: 0 }}
        elevation={0}
        style={{
          background: mode ? "#20202D" : "#20202D",
        }}
      >
        <Stack direction="row" justifyContent={"space-around"} py={2}>
          {tabs.map((item) => (
            <NavLink
              to={item.path}
              className={({ isActive }) => {
                return `d-flex flex-column items-center justify-center ${
                  isActive ? "active" : "inactive"
                }`;
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent={"center"}
                color={
                  location.pathname.includes(item.path) ? "#FFFFFF" : "#AFAFBB"
                }
              >
                <Typography>{item.icon}</Typography>
                <Typography variant="body2">{item.name}</Typography>
              </Stack>
            </NavLink>
          ))}
        </Stack>
      </AppBar>
    </React.Fragment>
  );
}
