import React, { useState, useEffect, Fragment, useRef } from "react";
import Slider from "./Slider";
import { motion } from "framer-motion";
import { Container, Stack } from "@mui/material";

const headerVariant = {
  initial: {
    y: -100,
  },
  final: {
    y: 0,
    transition: {
      type: "tween",
      duration: 0.5,
    },
  },
};

const Header = () => {
  const headerRef = useRef();
  const [headershrink, setHeadershrink] = useState(true);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [reveal, setReveal] = useState(false);
  let location = useRef(window.location.pathname);

  if (location.current !== window.location.pathname) {
    location.current = window.location.pathname;
    setScrollWidth(0);
    document.querySelector(".progress").style.width = "0";
  }

  const headerStyle = {
    width: "100%",
    margin: "0 auto",
    padding: headershrink ? "15px 0" : "20px 0",
    transition: "padding 0.5s ease",
  };

  const progress = {
    width: `${scrollWidth}%`,
    height: "2px",
    background: "black",
    transition: "width 0.2s ease",
  };

  useEffect(() => {
    isOpen
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  }, [isOpen]);

  const revealNavbar = () => {
    setReveal((prevState) => (prevState = !prevState));
    setIsOpen(true);
  };

  const displayNone = (e) => {
    if (
      e.target.classList.contains("slider") ||
      e.target.classList.contains("cancelSlider") ||
      e.target.nodeName === "A"
    ) {
      setReveal(false);
      setIsOpen(false);
    }
  };

  // const removeClass = type => {
  //     const cursor = document.querySelector('.cursor')
  //     type === 'remove' ?
  //         cursor.classList.remove('cursor-hover-ham') :
  //         cursor.classList.add('cursor-hover-ham')
  // }

  return (
    <Fragment>
      <div
        className="header-main-container"
        ref={headerRef}
        style={{
          background: "rgb(0,0,0,0)",
        }}
      >
        <div className="progress" style={progress}></div>
        <Container maxWidth="xl">
          <motion.div
            style={headerStyle}
            variants={headerVariant}
            initial="initial"
            animate="final"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <a href="/" className="logo">
                {/* <img
              width={headershrink ? "20" : "30"}
              src={require("../../images/logo.png")}
              alt="logo"
            />
             */}
                Prakrti
              </a>
              <svg
                // onMouseOver={() => removeClass('add')}
                // onMouseLeave={() => removeClass('remove')}
                onClick={revealNavbar}
                style={hamburger}
                width="70"
                height={headershrink ? "15" : "20"}
                viewBox="0 0 100 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="4" fill="#ACFF8E" />
                <rect y="13" width="50" height="4" fill="#ACFF8E" />
              </svg>
            </Stack>
          </motion.div>
        </Container>
      </div>

      <Slider
        displayNone={displayNone}
        reveal={reveal}
        closeSlider={() => {
          setReveal(false);
          setIsOpen(false);
        }}
      />
    </Fragment>
  );
};

const hamburger = {
  float: "right",
  cursor: "pointer",
  marginTop: "7px",
  transition: "transfrom 0.5s ease",
};

export default Header;
