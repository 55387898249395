import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

function ProtectedRoute({ children }) {
  const isAuthenticated = getCookie("token");

  let location = useLocation();

  if (!localStorage.getItem("token")) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  } else {
    return children;
  }
}

export default ProtectedRoute;
