import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "bookmarksStartup";
const initialState = {
  bookmarksStartupInd: [],
  bookmarksStartupIns: [],
  loadingInd: false,
  errorInd: null,
  loadingIns: false,
  errorInds: null,
};

export const bookmarksStartupIndAPI = createAsyncThunk(
  "bookmarks/startupind",
  async ({ search, skip, limit, id, type }) => {
    var formData = {};

    formData["_id"] = id;

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/individuals?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

export const bookmarksStartupInsAPI = createAsyncThunk(
  "bookmarks/startupins",
  async ({ search, skip, limit, id, type }) => {
    var formData = {};

    formData["id"] = id;

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/institutions?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const bookmarksStartupSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksStartupIndAPI.pending, (state) => {
      state.bookmarksStartupInd = [];
      state.loadingInd = true;
      state.errorInd = null;
    });
    builder.addCase(bookmarksStartupIndAPI.fulfilled, (state, action) => {
      state.bookmarksStartupInd = action.payload.data;
      state.loadingInd = false;
      state.errorInd = null;
    });
    builder.addCase(bookmarksStartupIndAPI.rejected, (state, action) => {
      state.bookmarksStartupInd = [];
      state.loadingInd = false;
      state.errorInd = action.error.message;
    });
    builder.addCase(bookmarksStartupInsAPI.pending, (state) => {
      state.bookmarksStartupIns = [];
      state.loadingIns = true;
      state.errorIns = null;
    });
    builder.addCase(bookmarksStartupInsAPI.fulfilled, (state, action) => {
      state.bookmarksStartupIns = action.payload.data;
      state.loadingIns = false;
      state.errorIns = null;
    });
    builder.addCase(bookmarksStartupInsAPI.rejected, (state, action) => {
      state.bookmarksStartupIns = [];
      state.loadingIns = false;
      state.errorIns = action.error.message;
    });
  },
});

export default bookmarksStartupSlice.reducer;
