import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "bookmarksExpert";
const initialState = {
  bookmarksExpert: [],
  loading: false,
  error: null,
};

export const bookmarksExpertAPI = createAsyncThunk(
  "bookmarks/expert",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/experts?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`
    );
    return data.data;
  }
);

const bookmarksExpertSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksExpertAPI.pending, (state) => {
      state.bookmarksExpert = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(bookmarksExpertAPI.fulfilled, (state, action) => {
      state.bookmarksExpert = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bookmarksExpertAPI.rejected, (state, action) => {
      state.bookmarksExpert = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default bookmarksExpertSlice.reducer;
