import { Divider, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, Outlet } from "react-router-dom";
import MobilseSettingsLayout from "./Mobile";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  let location = useLocation();
  const tabs = [
    {
      id: 1,
      name: "Username Details",
      path: "/settings/userdetails",
    },
    {
      id: 2,
      name: "Password Settings",
      path: "/settings/change-password",
    },
    {
      id: 3,
      name: "Notification Settings",
      path: "/settings/notification",
    },
    {
      id: 4,
      name: "Delete Account",
      path: "/settings/delete-account",
    },
    {
      id: 5,
      name: "Help Center / FAQs",
      path: "/settings/help",
    },
  ];
  return (
    <React.Fragment>
      {dimension.width > 600 ? (
        <Grid
          container
          direction="row"
          spacing={1}
          sx={{
            mt: 15,
            mb: 10,
            p: 4,
            minHeight: "600px",
          }}
          className={mode ? "dark-bg-1" : "light-bg-1"}
        >
          <Grid item xs={3}>
            <Box>
              <Stack alignContent="center" direction="column" spacing={3}>
                {tabs.map((item) => (
                  <Link to={item.path}>
                    <Typography
                      variant={item.path == location.pathname ? "h6" : "body1"}
                      className={
                        item.path == location.pathname
                          ? mode
                            ? "dark-0"
                            : "light-0"
                          : mode
                          ? "dark-4"
                          : "light-4"
                      }
                    >
                      {item.name}
                    </Typography>
                  </Link>
                ))}
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={9}>
            <Box>
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <MobilseSettingsLayout />
      )}
    </React.Fragment>
  );
};

export default Index;
