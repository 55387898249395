import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../../utils/httpClient";
const name = "exploreStartup";
const initialState = {
  exploreStartup: [],
  loading: false,
  error: null,
};

export const exploreStartupAPI = createAsyncThunk(
  "explore/startup",
  async ({
    industries,
    stage,
    investment,
    country,
    search,
    skip,
    limit,
    type,
    types,
    id,
    model,
  }) => {
    var formData = {};
    if (industries?.length > 0) {
      formData["industries"] = industries;
    }
    if (stage?.length > 0) {
      formData["stage"] = stage;
    }
    if (investment?.length > 0) {
      formData["investment"] = investment;
    }
    if (country?.length > 0) {
      formData["country"] = country;
    }
    if (type == "Institutional" && types) {
      formData["institutionType"] = types;
    }
    let data;
    if (type == "All") {
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}investor?skip=${skip}&limit=${limit}${
          search != "" ? `&search=${search}` : ""
        }`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
    } else {
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}${
          type == "Individual" ? "individual" : "institution"
        }?skip=${skip}&limit=${limit}${
          search != "" ? `&search=${search}` : ""
        }`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
    }

    return data.data;
  }
);

const exploreStartupSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exploreStartupAPI.pending, (state) => {
      state.exploreStartup = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(exploreStartupAPI.fulfilled, (state, action) => {
      console.log("A", action.payload);
      state.exploreStartup = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(exploreStartupAPI.rejected, (state, action) => {
      state.exploreStartup = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default exploreStartupSlice.reducer;
