import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider, Container, CssBaseline } from "@mui/material";
import { darkTheme, lightTheme } from "./theme";
import Dimension from "./components/dimensions/Dimension";
import Routes from "./routes";
import Aos from "aos";
import "./app.css";
import "aos/dist/aos.css";
import ScrollToTop from "./utils/scrollToTop";
function App() {
  let store = useSelector((store) => store);
  useEffect(() => {
    Aos.init({
      duration: 1000,
      // disable: window.innerWidth < 768,
    });
  }, []);

  return (
    <ThemeProvider theme={store.mode.mode ? darkTheme : lightTheme}>
      <CssBaseline />
      {/* <Dimension /> */}
      {/* <Container maxWidth="lg"> */}
      <BrowserRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
      {/* </Container> */}
    </ThemeProvider>
  );
}

export default App;
