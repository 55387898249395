import React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Typography, Popover, Stack, Divider, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Link, useLocation, Outlet, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import { GoogleLogout } from "react-google-login";
// import { googleLogout } from "../../../redux/auth/google/googleSlice";
import { logout, googleLogout } from "../../../redux/auth/signin/signinSlice";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabs = [
    {
      id: 0,
      name: "My Profile",
      path: "/myprofile",
    },
    {
      id: 1,
      name: "Username Details",
      path: "/settings/userdetails",
    },
    {
      id: 2,
      name: "Password Settings",
      path: "/settings/change-password",
    },
    {
      id: 3,
      name: "Notification Settings",
      path: "/settings/notification",
    },
    {
      id: 4,
      name: "Delete Account",
      path: "/settings/delete-account",
    },
    {
      id: 5,
      name: "Help Center / FAQs",
      path: "/settings/help",
    },
  ];
  return (
    <React.Fragment>
      {location.pathname == "/settings" ? (
        <React.Fragment>
          <Typography
            color="secondary"
            sx={{
              mt: 2,
            }}
          >
            <ArrowBackOutlinedIcon />
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent={"center"}
            sx={{
              mt: 2,
            }}
          >
            <Typography variant="h3" color="secondary" fontWeight="500">
              Settings
            </Typography>
          </Stack>
          <Stack
            sx={{
              mt: 4,
            }}
            direction="column"
            spacing={2}
            divider={<Divider orientation="horizontal" />}
          >
            {tabs.map((item) => (
              <Link to={item.path}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignContent="center"
                >
                  <Typography color="secondary" variant="body1">
                    {item.name}
                  </Typography>
                  <Typography
                    className={mode ? "dark-2" : "light-2"}
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <ArrowForwardIosOutlinedIcon />
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              mt: 4,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{
                px: 4,
              }}
              onClick={() => {
                dispatch(logout());
                dispatch(googleLogout());
                navigate("/");
              }}
            >
              Logout
            </Button>
          </Stack>
        </React.Fragment>
      ) : (
        <Outlet />
      )}
    </React.Fragment>
  );
};

export default Index;
