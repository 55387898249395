import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedToCompany";
const initialState = {
  receivedToCompany: [],
  loading: false,
  error: null,
};

export const receivedToCompanyAPI = createAsyncThunk(
  "receivedToCompany",
  async ({ search, skip, limit, type }) => {
    var formData = {};
    let model;
    if (type == "Investors") {
      model = "individual";
    }
    if (type == "Experts") {
      model = "expert";
    }
    if (type == "Mentors") {
      model = "mentor";
    }
    formData["model"] = model;

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }company/recevied-interests/?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedToCompanySlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedToCompanyAPI.pending, (state) => {
      state.receivedToCompany = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedToCompanyAPI.fulfilled, (state, action) => {
      state.receivedToCompany = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedToCompanyAPI.rejected, (state, action) => {
      state.receivedToCompany = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedToCompanySlice.reducer;
