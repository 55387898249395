import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedToEvent";
const initialState = {
  receivedToEvent: [],
  loading: false,
  error: null,
};

export const receivedToEventAPI = createAsyncThunk(
  "receivedToEvent",
  async ({ search, skip, limit, id }) => {
    var formData = {};

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }event/recevied-interests/${id}?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedToEventSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedToEventAPI.pending, (state) => {
      state.receivedToEvent = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedToEventAPI.fulfilled, (state, action) => {
      state.receivedToEvent = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedToEventAPI.rejected, (state, action) => {
      state.receivedToEvent = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedToEventSlice.reducer;
