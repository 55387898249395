import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import axiosApi from "../../../utils/httpClient";
const name = "profile";
const initialState = {
  data: {},
  loading: false,
  message: "",
  error: null,
  success: false,
};

export const profileAPI = createAsyncThunk("auth/profile", async () => {
  const response = await axiosApi.get(
    `${process.env.REACT_APP_BASE_URL}auth/profile`
  );
  return response.data;
});

export const clearProfile = createAsyncThunk("auth/clearprofile", async () => {
  console.log("logged out");
});

const profileSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(profileAPI.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(profileAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = "";
      state.error = null;
      state.success = true;
    });
    builder.addCase(profileAPI.rejected, (state, action) => {
      state.loading = false;
      state.message = "";
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(clearProfile.fulfilled, (state, action) => {
      state.data = {};
      state.loading = false;
      state.message = "";
      state.error = null;
      state.success = false;
    });
  },
});

export default profileSlice.reducer;
