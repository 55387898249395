import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const name = "limit";
const initialState = {
  limit: 10,
  loading: false,
  error: null,
};

export const setLimit = createAsyncThunk("pagination/limit", async (limit) => {
  return limit;
});

const limitSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setLimit.fulfilled, (state, action) => {
      state.limit = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default limitSlice.reducer;
