import {
  Grid,
  Box,
  Stack,
  Typography,
  InputAdornment,
  TextField,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosApi from "../../utils/httpClient";
import NotePreview from "./NotePreview";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes } from "../../redux/notes/fetchNotes/fetchNotesSlice";
import { selectNote } from "../../redux/notes/selectNote/selectNoteSlice";
import ContentBlock from "./ContentBlock";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
const Index = ({ onClose }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.mode.mode);
  const note = useSelector((store) => store.note.note);
  const notes = useSelector((store) => store.notes.notes);
  const createNewNote = async () => {
    const response = await axiosApi.post(
      `${process.env.REACT_APP_BASE_URL}note`,
      {
        title: "New Note",
        note: "Notes",
      }
    );
    await dispatch(selectNote(response?.data?.data?._id));
    await dispatch(fetchNotes());
  };
  useEffect(() => {
    dispatch(fetchNotes());
  }, []);
  useEffect(() => {
    if (notes) {
      if (!note) {
        dispatch(selectNote(notes[0]?._id));
      }
    }
  }, [note, notes]);
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        sx={{
          height: "500px",
        }}
      >
        <Grid item xs={3}>
          <Box
            className="dark-bg-1"
            sx={{
              width: "100%",
            }}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{
                px: 2,
                pt: 2,
              }}
            >
              <Typography fontWeight={"500"} fontSize="24px" color="secondary">
                Notes
              </Typography>
              <Typography
                fontWeight={"500"}
                fontSize="24px"
                color="primary"
                sx={{
                  cursor: "pointer",
                }}
                onClick={createNewNote}
              >
                <AddBoxOutlinedIcon />
              </Typography>
            </Stack>
            <Box px={2} py={1}>
              <TextField
                fullWidth
                style={{
                  border: `1px solid ${mode ? "#77788C" : "77788C"}`,

                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
                size="small"
                placeholder="Search here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Stack
              direction="column"
              sx={{
                height: "400px",
                overflowY: "scroll",
              }}
              spacing={1}
              divider={<Divider orientation="horizontal" />}
            >
              {notes &&
                notes?.length > 0 &&
                notes
                  .slice(0)
                  .reverse()
                  .map((item) => (
                    <NotePreview
                      title={item?.title}
                      content={item?.note}
                      id={item._id}
                    />
                  ))}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: "#333345",
            }}
          >
            <ContentBlock onClose={onClose} />
          </Box>
        </Grid>
      </Grid>
      {/* <Typography color="secondary">
        <DisabledByDefaultIcon
          fontSize="large"
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-13px",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </Typography> */}
    </React.Fragment>
  );
};

export default Index;
