import React, { useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Divider, Grid, Stack, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link, Outlet, useNavigate } from "react-router-dom";
import { setDashboardProfile } from "../../../../redux/dashboardProfile/dashboardSlice";
const Index = () => {
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const dashboardProfile = useSelector(
    (store) => store.dashboardProfile.dashboardProfile
  );
  const location = useLocation();
  const navigate = useNavigate();
  const tabs = [
    {
      id: 1,
      name:
        dashboardProfile == "Companies"
          ? "Applications Recieved"
          : "Interests Received",
      path: "/dashboard/received",
    },

    {
      id: 2,
      name: "Interests Sent",
      path: "/dashboard/sent",
    },
  ];
  useEffect(() => {
    if (dashboardProfile == "") {
      dispatch(setDashboardProfile("Companies"));
    }
  }, [dashboardProfile]);
  return (
    <React.Fragment>
      <Box mt={dimension.width > 600 ? 15 : 3} mb={10}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <Typography
            color="secondary"
            variant="h4"
            sx={{
              fontWeight: "600",
            }}
          >
            Dashboard
          </Typography>
          <Typography
            color="secondary"
            variant="h4"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <CloseOutlinedIcon />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          alignContent="center"
          sx={{
            mt: 2,
          }}
        >
          <Button
            color={dashboardProfile == "Companies" ? "primary" : "secondary"}
            variant={dashboardProfile == "Companies" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Companies"));
            }}
          >
            companies
          </Button>

          <Button
            color={dashboardProfile == "Events" ? "primary" : "secondary"}
            variant={dashboardProfile == "Events" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Events"));
            }}
          >
            Events
          </Button>
        </Stack>
        <Box
          mt={5}
          sx={{ width: "100%" }}
          p={dimension.width > 600 && 3}
          className={
            dimension.width > 600 ? (mode ? "dark-bg-1" : "light-bg-1") : ""
          }
        >
          <Stack
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            spacing={dimension.width <= 600 ? 3 : 7}
            sx={{
              overflowX: dimension.width <= 600 && "scroll",
              overflow: "hidden",
            }}
          >
            {tabs.map((item) => (
              <Box>
                <Link to={item.path}>
                  <Typography
                    className={
                      location.pathname === item.path
                        ? mode
                          ? "dark-0"
                          : "light-0"
                        : mode
                        ? "dark-4"
                        : "light-4"
                    }
                    variant={dimension.width > 600 ? "body1" : "body2"}
                    align="center"
                    mb={1}
                    style={{
                      borderBottom: `${
                        location.pathname == item.path
                          ? "3px solid #eb4d89"
                          : "none"
                      }`,
                    }}
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Stack>

          <Divider
            orientation="horizontal"
            sx={{
              my: 4,
            }}
          />
          <Outlet />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Index;
