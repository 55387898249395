import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import HeaderNav from "../HeaderNav";
import BottomNav from "../BottomNav";
import {
  Container,
  Stack,
  Typography,
  Modal,
  Box,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { profileAPI } from "../../redux/auth/profile/profileSlice";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { Autosave, useAutosave } from "react-autosave";
import axiosApi from "../../utils/httpClient";
import Notes from "../../components/Notes";
function MainLayout() {
  let store = useSelector((store) => store);
  const [note, setNote] = React.useState("");
  const [noteId, setNoteId] = useState(null);
  const profile = useSelector((store) => store.profile);
  const [newNote, setNewNote] = useState(false);
  const signin = useSelector((store) => store.signin);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // useEffect(() => {
  //   if (profile.success) {
  //     setCookie("userdata", profile.data);
  //   } else {
  //     if (profile.error) {
  //       console.log(profile.error);
  //     }
  //   }
  // }, [profile]);

  const getNotes = async () => {
    try {
      const response = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}note`
      );

      if (response?.data?.data) {
        await setNoteId(response?.data?.data?._id);
        await setNote(response?.data?.data?.note);
      } else {
        await setNewNote(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postNotes = async () => {
    if (newNote) {
      try {
        const response = await axiosApi.post(
          `${process.env.REACT_APP_BASE_URL}note`,
          {
            note: note,
          }
        );

        setNewNote(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axiosApi.patch(
          `${process.env.REACT_APP_BASE_URL}note/${noteId}`,
          {
            note: note,
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  // useAutosave({ data: note, onSave: postNotes });
  useEffect(() => {
    getNotes();
  }, []);
  useEffect(() => {
    if (note) {
      postNotes();
    }
  }, [note]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(profileAPI());
    }
  }, [localStorage]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: "1200px",
    height: "500px",
    maxHeight: "500px",
    margin: "auto",
    border: "none",
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Stack direction="column">
          {store.dimension.width >= 600 && <HeaderNav />}
          {store.dimension.width < 600 && localStorage.getItem("token") && (
            <BottomNav />
          )}
          <Outlet />
        </Stack>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="dark-bg-1">
            <Notes onClose={handleClose} />
          </Box>
        </Modal>
      </Container>
      {localStorage.getItem("token") && (
        <div
          style={{
            position: "fixed",
            bottom: "30px",
            left: "30px",
          }}
        >
          <Box
            sx={{
              height: "40px",
              width: "40px",
              background: "#eb4d89",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color="secondary"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                getNotes();
                handleOpen();
              }}
            >
              <EditIcon />
            </Typography>
          </Box>
        </div>
      )}
    </React.Fragment>
  );
}
export default MainLayout;
