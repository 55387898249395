import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../../utils/httpClient";
import * as qs from "qs";
const name = "conversations";
const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
};

export const fetchConversations = createAsyncThunk(
  "conversations/fetchConversations",
  async ({ id, model, type }) => {
    let data;
    if (type == "inv-proj") {
      var formData = {};
      formData["id"] = id;
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/companies-get-all/${id}`
      );
    } else if (type == "all") {
      var formData = {};
      formData["model"] = "job";
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/conversations`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
    } else if (type == "single-job") {
      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/job/${id}`
      );
    } else {
      var formData = {};
      formData["id"] = id;
      if (model != "All") {
        if (model == "Investors") {
          formData["model"] = "investor";
        }
        if (model == "Mentors") {
          formData["model"] = "mentor";
        }
        if (model == "Experts") {
          formData["model"] = "expert";
        }
      }

      data = await axiosApi.get(
        `${process.env.REACT_APP_BASE_URL}chat/conversations`,
        {
          params: formData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
    }
    return data.data;
  }
);

const conversationsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchConversations.pending, (state) => {
      state.data = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchConversations.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(fetchConversations.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default conversationsSlice.reducer;
