import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";

function MultiStartupAccount({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();

  if (
    profile?.data?.startup &&
    profile?.data?.startup?.applicationStage &&
    profile?.data?.startup?.applicationStage == "approved"
  ) {
    return (
      <Navigate to={"/search/startup"} replace state={{ from: location }} />
    );
  } else {
    return children;
  }
}

export default MultiStartupAccount;
