import {
  Typography,
  Box,
  Stack,
  Popover,
  Divider,
  TextField,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { selectNote } from "../../../redux/notes/selectNote/selectNoteSlice";
import { SecurityUpdateWarningTwoTone } from "@mui/icons-material";
import axiosApi from "../../../utils/httpClient";
import { useDetectClickOutside } from "react-detect-click-outside";
import { fetchNotes } from "../../../redux/notes/fetchNotes/fetchNotesSlice";
import ConfirmationPopup from "../../ConfirmationsPopup";
const Index = ({ title, content, id }) => {
  const mode = useSelector((store) => store.mode.mode);
  const [titleEdit, setTitleEdit] = useState(false);
  const [titleValue, setTitleValue] = useState(title);
  const [mouseHover, setMouseHover] = useState(false);
  const note = useSelector((store) => store.note.note);
  const notes = useSelector((store) => store.notes.notes);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const updateNote = async () => {
    const response = await axiosApi.patch(
      `${process.env.REACT_APP_BASE_URL}note/${id}`,
      {
        title: titleValue,
        note: content,
      }
    );
  };

  const [open, setOpen] = useState(false);
  const idx = open ? "simple-popover" : undefined;
  useEffect(() => {
    if (title != titleValue) {
      updateNote();
    }
  }, [titleValue]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setTitleEdit(false);
    }
  };

  const deleteNote = async () => {
    const response = await axiosApi.delete(
      `${process.env.REACT_APP_BASE_URL}note/${id}`
    );
    await selectNote("");
    await dispatch(fetchNotes());
    if (notes?.length > 0) {
      await dispatch(selectNote(notes[0]._id));
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 400,
    bgcolor: "#333345",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 3,
  };

  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ConfirmationPopup
            onSubmit={deleteNote}
            onCancel={closeModal}
            message={"Do you want to Delete the Note"}
            title={"Delete Note"}
          />
        </Box>
      </Modal>
      <Box
        className={
          id == note
            ? mode
              ? "dark-bg-4"
              : "light-bg-2"
            : mouseHover
            ? mode
              ? "dark-bg-3"
              : "dark-bg-3"
            : ""
        }
        sx={{
          cursor: "pointer",
        }}
        onMouseOver={() => setMouseHover(true)}
        onMouseOut={() => setMouseHover(false)}
        onClick={() => {
          dispatch(selectNote(id));
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
        >
          <Stack
            direction={"column"}
            spacing={1}
            sx={{
              py: 1,
              px: 2,
            }}
          >
            {titleEdit ? (
              <TextField
                autoFocus={true}
                value={titleValue}
                id="standard-basic"
                variant="standard"
                onChange={(e) => {
                  setTitleValue(e.target.value);
                }}
                sx={{
                  background: "#000",
                  px: "4px",
                }}
                InputProps={{ disableUnderline: true }}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <Typography
                fontSize="18px"
                fontWeight="500"
                color="secondary"
                sx={{
                  WebkitLineClamp: 1,
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {titleValue}
              </Typography>
            )}

            <Typography
              fontSize="14px"
              variant="body2"
              color="secondary"
              sx={{
                WebkitLineClamp: 2,
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              ></span>
            </Typography>
          </Stack>
          {id == note && (
            <>
              <Typography
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                  setOpen(true);
                }}
              >
                <MoreVertIcon />
              </Typography>
              <Popover
                id={idx}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Stack
                  direction="column"
                  divider={<Divider orientation="horizontal" />}
                >
                  <Typography
                    fontSize="14px"
                    sx={{ p: 1, cursor: "pointer" }}
                    onClick={() => {
                      setTitleEdit(true);
                      setOpen(false);
                    }}
                  >
                    Edit Title
                  </Typography>
                  <Typography
                    fontSize="14px"
                    sx={{ p: 1, cursor: "pointer" }}
                    onClick={() => {
                      setOpenModal(true);
                      setOpen(false);
                    }}
                  >
                    Delete Note
                  </Typography>
                </Stack>
              </Popover>
            </>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Index;
