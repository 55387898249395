import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "applicationsSent";
const initialState = {
  applicationSentInd: [],
  applicationSentIns: [],
  loading: false,
  error: null,
};

export const applicationSentIndAPI = createAsyncThunk(
  "applicationsentind",
  async ({ search, skip, limit, id }) => {
    var formData = {};

    formData["id"] = id;

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }application/individual-send?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

export const applicationSentInsAPI = createAsyncThunk(
  "applicationsentins",
  async ({ search, skip, limit, id }) => {
    var formData = {};

    formData["id"] = id;

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }application/institution-send?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const applicationsSentSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(applicationSentIndAPI.pending, (state) => {
      state.applicationSentInd = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(applicationSentIndAPI.fulfilled, (state, action) => {
      state.applicationSentInd = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(applicationSentIndAPI.rejected, (state, action) => {
      state.applicationSentInd = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(applicationSentInsAPI.pending, (state) => {
      state.applicationSentIns = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(applicationSentInsAPI.fulfilled, (state, action) => {
      state.applicationSentIns = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(applicationSentInsAPI.rejected, (state, action) => {
      state.applicationSentIns = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default applicationsSentSlice.reducer;
