import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedToExpert";
const initialState = {
  receivedToExpert: [],
  loading: false,
  error: null,
};

export const receivedToExpertAPI = createAsyncThunk(
  "receivedToExpert",
  async ({ search, skip, limit }) => {
    var formData = {};

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }application/expert/recevied/?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedToExpertSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedToExpertAPI.pending, (state) => {
      state.receivedToExpert = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedToExpertAPI.fulfilled, (state, action) => {
      state.receivedToExpert = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedToExpertAPI.rejected, (state, action) => {
      state.receivedToExpert = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedToExpertSlice.reducer;
