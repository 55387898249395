import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as qs from "qs";
import axiosApi from "../../utils/httpClient";
const name = "receivedJobs";
const initialState = {
  receivedJobs: [],
  loading: false,
  error: null,
};

export const receivedJobsAPI = createAsyncThunk(
  "receivedJobs",
  async ({ search, skip, limit, id, status }) => {
    var formData = {};
    // formData["status"] = "applied";

    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }job/recevied-applications/${id}?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`,
      {
        params: formData,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      }
    );
    return data.data;
  }
);

const receivedJobsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(receivedJobsAPI.pending, (state) => {
      state.receivedJobs = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(receivedJobsAPI.fulfilled, (state, action) => {
      state.receivedJobs = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(receivedJobsAPI.rejected, (state, action) => {
      state.receivedJobs = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default receivedJobsSlice.reducer;
