import MainLayout from "../main";
import LandingLayout from "../landing";
import React from "react";
import { useLocation } from "react-router-dom";
const Index = () => {
  const location = useLocation();
  if (localStorage.getItem("token")) {
    return <MainLayout />;
  } else {
    if (location.pathname == "/") {
      return <LandingLayout />;
    } else {
      return <MainLayout />;
    }
  }
};

export default Index;
