import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./httpClient";

import { useSelector } from "react-redux";
import Loader from "../components/Loader";

function HomeRedirect({ children }) {
  const isAuthenticated = getCookie("token");

  const profile = useSelector((store) => store.profile);
  let location = useLocation();
  if (localStorage.getItem("profile")) {
    if (localStorage.getItem("profile") == "Startup") {
      return <Navigate to="/search/investors" />;
    } else if (localStorage.getItem("profile") == "Investor") {
      return <Navigate to="/search/companies" />;
    } else if (localStorage.getItem("profile") == "Mentor") {
      return <Navigate to="/search/companies" />;
    } else if (localStorage.getItem("profile") == "Expert") {
      return <Navigate to="/search/jobs" />;
    } else {
      return children;
    }
  } else {
    return children;
  }
}

export default HomeRedirect;
