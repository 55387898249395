/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { NestedMenuItem } from "mui-nested-menu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  AppBar,
  Typography,
  Button,
  Stack,
  Divider,
  Container,
  Avatar,
  Tooltip,
  IconButton,
  Popover,
  Modal,
} from "@mui/material";
import PropTypes from "prop-types";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useTheme } from "@emotion/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import stringAvatar from "../components/Utils/stringAvatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { googleLogout } from "../redux/auth/google/googleSlice";
import { logout, googleLogout } from "../redux/auth/signin/signinSlice";
import { GoogleLogout } from "react-google-login";
import { getCookie } from "../utils/httpClient";

import { switchProfile } from "../redux/switchprofile/switchProfileSlice";
import { clearProfile } from "../redux/auth/profile/profileSlice";
import ConfirmationPop from "../components/ConfirmationsPopup";
import Notifications from "../components/Notifications";
import { SocketContext } from "../context/socket";
import Snackbar from "../components/Snackbar";
import { selectProject } from "../redux/selectedproject/selectedProjectSlice";
import axiosApi from "../utils/httpClient";
import Slide from "@mui/material/Slide";
import { setDashboardProfile } from "../redux/dashboardProfile/dashboardSlice";
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function HeaderNav(props) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationItem, setNotificationItem] = useState();
  const mode = useSelector((store) => store.mode.mode);
  let dimension = useSelector((store) => store.dimension);
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popupIndex, setPopupIndex] = useState(0);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const handlePopoverClose = () => {
    setPopupIndex(0);
  };

  const open = Boolean(anchorEl);
  const [fullName, setFullName] = React.useState(null);

  const [both, setBoth] = useState(false);
  const name = getCookie("fullName");
  const profile = useSelector((store) => store.profile);
  const currentProfile = useSelector((store) => store.switch.profile);
  React.useEffect(() => {
    setFullName(name);
  }, [name]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const switchProfileHandler = async (type) => {
    if (type == "Startup") {
      localStorage.setItem("profile", "Startup");
      const resp = await axiosApi.get(`auth/switch-profile?to=Company`);

      await navigate(`/search/investors`);
    } else if (type == "Investor") {
      localStorage.setItem("profile", "Investor");
      const tt = (await profile?.data?.individual)
        ? "Individual"
        : "Institution";
      const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);

      await navigate(`/search/companies`);
    } else {
      localStorage.setItem("profile", "Mentor");
      const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);

      await navigate(`/search/companies`);
    }
  };

  useEffect(() => {
    if (profile?.data) {
      let id;
      if (localStorage.getItem("profile") == "Startup") {
        const a = profile;

        id = profile?.data?.company?._id;
      } else {
        if (profile?.data?.individual) {
          id = profile?.data?.individual?._id;
        } else {
          id = profile?.data?.institution?._id;
        }
      }

      socket.emit("start-notifications", { _id: `${id}` });
    }
  }, [profile]);

  const notificationListener = async (notification) => {
    setNotificationItem(notification);
    setSnackOpen(true);
  };

  const notificationClick = () => {
    if (notificationItem.type == "receive-application") {
      dispatch(
        selectProject({
          name: notificationItem.projectName,
          id: notificationItem.projectId,
        })
      );
      navigate(`/dashboard/received`);
    } else if (notificationItem.type == "application-action") {
      navigate(`/dashboard/sent`);
    }
  };

  useEffect(() => {
    socket.on("notification", notificationListener);
  }, []);

  const responseGoogleLogoutSuccess = (response) => {
    console.log(response);
  };

  const responseGoogleLogoutFail = () => {
    console.log("Fail");
  };

  const logoutHandle = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profile");

    // removeCookie("profileToken");

    dispatch(logout());
    dispatch(googleLogout());
    dispatch(clearProfile());

    closeModal();
  };

  // const checkBothProfiles = () => {
  //   if (profile?.success) {
  //     if (
  //       profile?.data?.company!== null &&
  //       (profile?.data?.individual!== null ||
  //         profile?.data?.institution!== null)
  //     ) {
  //       return true;
  //     } else {
  //       if (profile?.data?.company!== null) {
  //         if (currentProfile === "Investor" || currentProfile === null) {
  //           dispatch(switchProfile("Startup"));
  //         }
  //       } else if (
  //         profile?.data?.individual!== null
  //       ) {
  //         if (currentProfile === "Startup" || currentProfile == null) {
  //           dispatch(switchProfile("Investor"));
  //         }
  //       } else if (
  //         profile?.data?.institution!== null
  //       ) {
  //         if (currentProfile === "Startup" || currentProfile == null) {
  //           dispatch(switchProfile("Investor"));
  //         }
  //       } else {
  //         dispatch(switchProfile());
  //       }

  //       return false;
  //     }
  //   }
  // };
  const [companyProfile, setCompanyProfile] = useState(false);
  const [investorProfile, setInvestorProfile] = useState(false);
  const [mentorProfile, setMentorProfile] = useState(false);
  const [expertProfile, setExpertProfile] = useState(false);

  // const checkProfiles = async () => {
  //   if (profile?.success) {
  //     if (profile?.data?.company !== null) {
  //       setCompanyProfile(true);
  //     }
  //     if (
  //       profile?.data?.individual !== null ||
  //       profile?.data?.institution !== null
  //     ) {
  //       setInvestorProfile(true);
  //     }
  //     if (profile?.data?.mentor?.firstName) {
  //       setMentorProfile(true);
  //     }
  //     if (profile?.data?.expert?.firstName) {
  //       setExpertProfile(true);
  //     }
  //   }

  //   if (!localStorage.getItem("profile")) {
  //     if (
  //       profile?.data?.user?.whichProfile &&
  //       profile?.data?.user?.whichProfile != "User"
  //     ) {
  //       const wp = profile?.data?.user?.whichProfile;
  //       if (wp == "Individual" || wp == "Institution") {
  //         setCookie("profile", "Investor");
  //       } else if (wp == "Company") {
  //         setCookie("profile", "Startup");
  //       } else {
  //         setCookie("profile", wp);
  //       }
  //     } else {
  //       if (companyProfile) {
  //         await setCookie("profile", "Startup");
  //         const resp = await axiosApi.get(`auth/switch-profile?to=Company`);
  //         await setCookie("profileToken", resp?.data?.data?.profileToken);
  //       } else if (investorProfile) {
  //         await setCookie("profile", "Investor");
  //         const tt = (await profile?.data?.individual)
  //           ? "Individual"
  //           : "Institution";
  //         const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);
  //         await setCookie("profileToken", resp?.data?.data?.profileToken);
  //       } else if (mentorProfile) {
  //         await setCookie("profile", "Mentor");
  //         const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);
  //         await setCookie("profileToken", resp?.data?.data?.profileToken);
  //       } else if (expertProfile) {
  //         await setCookie("profile", "Expert");
  //         const resp = await axiosApi.get(`auth/switch-profile?to=Expert`);
  //         await setCookie("profileToken", resp?.data?.data?.profileToken);
  //       }
  //     }
  //   }
  // };

  const checkProfiles = async () => {
    if (profile?.success) {
      if (profile?.data?.company !== null) {
        setCompanyProfile(true);
      }
      if (
        profile?.data?.individual !== null ||
        profile?.data?.institution !== null
      ) {
        setInvestorProfile(true);
      }
      if (profile?.data?.mentor?.firstName) {
        setMentorProfile(true);
      }
      if (profile?.data?.expert?.firstName) {
        setExpertProfile(true);
      }
    }

    if (!localStorage.getItem("profile")) {
      if (
        profile?.data?.user?.whichProfile &&
        profile?.data?.user?.whichProfile != "User"
      ) {
        const wp = profile?.data?.user?.whichProfile;
        if (wp == "Individual" || wp == "Institution") {
          localStorage.setItem("profile", "Investor");
        } else if (wp == "Company") {
          localStorage.setItem("profile", "Startup");
        } else {
          localStorage.setItem("profile", wp);
        }
      } else {
        if (companyProfile) {
          localStorage.setItem("profile", "Startup");
          const resp = await axiosApi.get(`auth/switch-profile?to=Company`);
        } else if (investorProfile) {
          localStorage.setItem("profile", "Investor");
          const tt = (await profile?.data?.individual)
            ? "Individual"
            : "Institution";
          const resp = await axiosApi.get(`auth/switch-profile?to=${tt}`);
        } else if (mentorProfile) {
          localStorage.setItem("profile", "Mentor");
          const resp = await axiosApi.get(`auth/switch-profile?to=Mentor`);
        } else if (expertProfile) {
          localStorage.setItem("profile", "Expert");
          const resp = await axiosApi.get(`auth/switch-profile?to=Expert`);
        }
      }
    }
  };

  const checkBothProfiles = () => {
    if (profile?.success) {
      if (
        profile?.data?.company !== null &&
        (profile?.data?.individual !== null ||
          profile?.data?.institution !== null)
      ) {
        if (!localStorage.getItem("profile")) {
          localStorage.setItem("profile", "Startup");
        }
        setBoth(true);
      } else {
        if (profile?.data?.company !== null) {
          if (!localStorage.getItem("profile")) {
            localStorage.setItem("profile", "Startup");
          }
        } else if (profile?.data?.individual !== null) {
          if (!localStorage.getItem("profile")) {
            localStorage.setItem("profile", "Investor");
          }
        } else if (profile?.data?.institution !== null) {
          if (!localStorage.getItem("profile")) {
            localStorage.setItem("profile", "Investor");
          }
        }

        setBoth(false);
      }
    }
  };

  useEffect(() => {
    // checkBothProfiles();
    checkProfiles();
  }, [profile]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    width: 400,
    bgcolor: "#333345",
    border: "2px solid #333345",

    boxShadow: 24,
    p: 3,
  };

  const [snackOpen, setSnackOpen] = useState(false);
  const snackClose = () => setSnackOpen(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const homeHandler = () => {
    if (localStorage.getItem("profile")) {
      if (localStorage.getItem("profile") == "Startup") {
        navigate(`/search/investors`);
      } else if (localStorage.getItem("profile") == "Investor") {
        navigate(`/search/companies`);
      } else if (localStorage.getItem("profile") == "Mentor") {
        navigate(`/search/companies`);
      } else if (localStorage.getItem("profile") == "Expert") {
        navigate(`/search/jobs`);
      }
    } else {
      navigate(`/`);
    }
  };
  return (
    <React.Fragment>
      <Snackbar
        open={snackOpen}
        message={notificationItem?.message}
        onClose={snackClose}
        notificationClick={notificationClick}
      />
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ConfirmationPop
            onSubmit={logoutHandle}
            onCancel={closeModal}
            message={"Do you want to logout?"}
            title={"Logout"}
          />
        </Box>
      </Modal>
      <HideOnScroll {...props}>
        <AppBar
          sx={{
            alignItems: "center",
            minWidth: "100%",
            bgcolor: theme.palette.background.default,
          }}
          elevation={0}
        >
          <Container maxWidth="lg">
            <Box sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                alignContent={"center"}
                py={3}
                sx={{}}
              >
                <Stack alignItems="flex-start">
                  <div className="d-flex items-center">
                    <Typography
                      variant="h4"
                      color="primary"
                      className="logo"
                      fontWeight={"600"}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={homeHandler}
                    >
                      BeBlossom
                    </Typography>
                  </div>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  alignItems="center"
                  textAlign="center"
                >
                  <Stack gap={2} direction="row" alignItems={"center"}>
                    {localStorage.getItem("token") && (
                      <>
                        {localStorage.getItem("profile") == "Startup" && (
                          <React.Fragment>
                            <Link to="/search/investors">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(
                                        `/search/investors`
                                      )
                                        ? "2px solid #eb4d89"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Investors
                                </span>
                              </Button>
                            </Link>
                            <Link to="/search/mentor">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(
                                        `/search/mentor`
                                      )
                                        ? "2px solid #eb4d89"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Mentors
                                </span>
                              </Button>
                            </Link>
                            <Link to="/search/expert">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(
                                        `/search/expert`
                                      )
                                        ? "2px solid #eb4d89"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Experts
                                </span>
                              </Button>
                            </Link>
                          </React.Fragment>
                        )}
                        {(localStorage.getItem("profile") == "Investor" ||
                          localStorage.getItem("profile") == "Expert" ||
                          localStorage.getItem("profile") == "Mentor") && (
                          <Link to="/search/companies">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(
                                      `/search/companies`
                                    )
                                      ? "2px solid #eb4d89"
                                      : "none"
                                  }`,
                                }}
                              >
                                Companies
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") == "Startup" ||
                          localStorage.getItem("profile") == "Investor" ||
                          localStorage.getItem("profile") == "Expert" ||
                          localStorage.getItem("profile") == "Mentor") && (
                          <Link to="/search/events">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(`/search/events`)
                                      ? "2px solid #eb4d89"
                                      : "none"
                                  }`,
                                }}
                              >
                                Events
                              </span>
                            </Button>
                          </Link>
                        )}
                        {(localStorage.getItem("profile") == "Mentor" ||
                          localStorage.getItem("profile") == "Expert") && (
                          <Link to="/search/jobs">
                            <Button variant="text" color="secondary">
                              <span
                                style={{
                                  borderBottom: `${
                                    location.pathname.includes(`/search/jobs`)
                                      ? "2px solid #eb4d89"
                                      : "none"
                                  }`,
                                }}
                              >
                                Jobs
                              </span>
                            </Button>
                          </Link>
                        )}
                        {/* {localStorage.getItem("profile") == "Investor" && (
                        <Link to="/search/projects">
                          <Button variant="text" color="secondary">
                            <span
                              style={{
                                borderBottom: `${
                                  location.pathname.includes(`/search/projects`)
                                    ? "2px solid #eb4d89"
                                    : "none"
                                }`,
                              }}
                            >
                              Marketplace
                            </span>
                          </Button>
                        </Link>
                      )} */}
                      </>
                    )}

                    {localStorage.getItem("token") ? (
                      <>
                        {localStorage.getItem("profile") && (
                          <>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                            />
                            <Link
                              to={
                                localStorage.getItem("profile") == "Startup"
                                  ? `/dashboard/received`
                                  : `/dashboard/sent`
                              }
                            >
                              <Button
                                variant="text"
                                color="secondary"
                                onClick={() => {
                                  dispatch(setDashboardProfile(""));
                                }}
                              >
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(`/dashboard`)
                                        ? "2px solid #eb4d89"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Dashboard
                                </span>
                              </Button>
                            </Link>
                            <Link to="/inbox">
                              <Button variant="text" color="secondary">
                                <span
                                  style={{
                                    borderBottom: `${
                                      location.pathname.includes(`/inbox`)
                                        ? "2px solid #eb4d89"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  Inbox
                                </span>
                              </Button>
                            </Link>
                            <NotificationsNoneOutlinedIcon
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                if (popupIndex == 1) {
                                  setPopupIndex(0);
                                } else {
                                  setAnchorEl1(e.currentTarget);
                                  setPopupIndex(1);
                                }
                              }}
                              ref={anchorEl1}
                            />

                            <Popover
                              open={popupIndex == 1}
                              anchorEl={anchorEl1}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <Notifications />
                            </Popover>
                          </>
                        )}
                        <>
                          <Box>
                            <Tooltip title={user?.user?.fullName}>
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                style={{
                                  verticalAlign: "middle",
                                }}
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <Avatar
                                  {...stringAvatar(user?.user?.fullName)}
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={handleClick}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Menu
                              classes={{
                                popover: "dark-bg-2",
                              }}
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                className: mode ? "dark-bg-2" : "light-bg-2",
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: mode ? "#333345" : "#333345",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  navigate(`/myprofile`);
                                }}
                              >
                                My Profile
                              </MenuItem>

                              <Divider />

                              {localStorage.getItem("profile") == "Startup" && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      navigate(`/my-jobs`);
                                    }}
                                  >
                                    My Jobs
                                  </MenuItem>
                                  <Divider />
                                </>
                              )}
                              <MenuItem
                                onClick={() => {
                                  navigate(`/my-events`);
                                }}
                              >
                                My Events
                              </MenuItem>

                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  navigate(`/bookmarks`);
                                }}
                              >
                                Bookmarks
                              </MenuItem>
                              <Divider />
                              {/* {both ? (
                              <NestedMenuItem
                                label="Switch Profile"
                                parentMenuOpen={open}
                              >
                                <MenuItem
                                  onClick={() => {
                                    switchProfileHandler();
                                  }}
                                >
                                  Switch to Investor
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    switchProfileHandler();
                                  }}
                                >
                                  Switch to Mentor
                                </MenuItem>
                              </NestedMenuItem>
                            ) : (
                              <>
                                {profile?.data?.company?.applicationStage ==
                                "submitted" ? (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `/investor-create/type-of-investor`
                                      );
                                    }}
                                  >
                                    Become an Investor
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(
                                        `/startup-create/startup-details`
                                      );
                                    }}
                                  >
                                    Create a Startup
                                  </MenuItem>
                                )}
                              </>
                            )}
                             */}
                              {(companyProfile ||
                                investorProfile ||
                                mentorProfile) && (
                                <NestedMenuItem
                                  label="Switch Profile"
                                  parentMenuOpen={open}
                                >
                                  {localStorage.getItem("profile") !=
                                    "Startup" &&
                                    companyProfile && (
                                      <MenuItem
                                        onClick={() => {
                                          switchProfileHandler("Startup");
                                        }}
                                      >
                                        Switch to Company
                                      </MenuItem>
                                    )}
                                  {localStorage.getItem("profile") !=
                                    "Startup" &&
                                    !companyProfile && (
                                      <MenuItem
                                        onClick={() => {
                                          navigate(
                                            `/startup-create/startup-details`
                                          );
                                        }}
                                      >
                                        Create Compaany
                                      </MenuItem>
                                    )}
                                  {localStorage.getItem("profile") !=
                                    "Investor" &&
                                    investorProfile && (
                                      <MenuItem
                                        onClick={() => {
                                          switchProfileHandler("Investor");
                                        }}
                                      >
                                        Switch to Investor
                                      </MenuItem>
                                    )}
                                  {localStorage.getItem("profile") !=
                                    "Investor" &&
                                    !investorProfile && (
                                      <MenuItem
                                        onClick={() => {
                                          navigate(
                                            `/investor-create/type-of-investor`
                                          );
                                        }}
                                      >
                                        Become Investor
                                      </MenuItem>
                                    )}
                                  {localStorage.getItem("profile") !=
                                    "Mentor" &&
                                    mentorProfile && (
                                      <MenuItem
                                        onClick={() => {
                                          switchProfileHandler("Mentor");
                                        }}
                                      >
                                        Switch to Mentor
                                      </MenuItem>
                                    )}
                                  {localStorage.getItem("profile") !=
                                    "Mentor" &&
                                    !mentorProfile && (
                                      <MenuItem
                                        onClick={() => {
                                          navigate(
                                            `/mentor-create/become-mentor`
                                          );
                                        }}
                                      >
                                        Become Mentor
                                      </MenuItem>
                                    )}
                                </NestedMenuItem>
                              )}

                              <Divider />

                              <MenuItem
                                onClick={() => {
                                  navigate(`/preferences`);
                                }}
                              >
                                My Preferences
                              </MenuItem>

                              <Divider />

                              <MenuItem
                                onClick={() => {
                                  navigate(`/settings/userdetails`);
                                }}
                              >
                                Account Settings
                              </MenuItem>

                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  // removeCookie("user");
                                  // removeCookie("token");
                                  // removeCookie("refreshToken");
                                  // dispatch(logout());
                                  // dispatch(googleLogout());
                                  // dispatch(clearProfile());

                                  setOpenModal(true);
                                }}
                              >
                                <GoogleLogout
                                  clientId="514017956132-eadvtga0tqcenl2mt5khs6o5jbpd8sql.apps.googleusercontent.com"
                                  render={(renderProps) => (
                                    <p
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      Logout
                                    </p>
                                  )}
                                  buttonText="Login"
                                  onSuccess={responseGoogleLogoutSuccess}
                                  onFailure={responseGoogleLogoutFail}
                                  cookiePolicy={"single_host_origin"}
                                />
                              </MenuItem>
                            </Menu>
                          </Box>
                        </>
                      </>
                    ) : (
                      <>
                        <Link to="/signin">
                          <Button variant="text" color="secondary">
                            Login
                          </Button>
                        </Link>
                        <Link to="/register">
                          <Button variant="contained">Get Started</Button>
                        </Link>
                      </>
                    )}
                  </Stack>
                  {dimension.width > 600 && <></>}
                </Stack>
              </Stack>
            </Box>
          </Container>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}
