import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillFacebook,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { FaInstagramSquare } from "react-icons/fa";
import { BsArrowRightSquare } from "react-icons/bs";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getNextTitle = () => {
    if (
      location.pathname == "/landingpage" ||
      location.pathname == "/landingpage/" ||
      location.pathname == "/"
    ) {
      return "Startups";
    } else if (
      location.pathname == "/landingpage/startup" ||
      location.pathname == "/landingpage/startup/"
    ) {
      return "Investors";
    } else if (
      location.pathname == "/landingpage/investor" ||
      location.pathname == "/landingpage/investor/"
    ) {
      return "Mentors";
    } else if (
      location.pathname == "/landingpage/mentor" ||
      location.pathname == "/landingpage/mentor/"
    ) {
      return "Experts";
    } else if (
      location.pathname == "/landingpage/expert" ||
      location.pathname == "/landingpage/expert/"
    ) {
      return "Partners";
    } else if (
      location.pathname == "/landingpage/partnership" ||
      location.pathname == "/landingpage/partnership/"
    ) {
      return "About Us";
    } else {
      return "Home";
    }
  };
  const getNext = () => {
    if (
      location.pathname == "/landingpage" ||
      location.pathname == "/landingpage/" ||
      location.pathname == "/"
    ) {
      return "Up Next";
    } else if (
      location.pathname == "/landingpage/startup" ||
      location.pathname == "/landingpage/startup/"
    ) {
      return "Up Next";
    } else if (
      location.pathname == "/landingpage/investor" ||
      location.pathname == "/landingpage/investor/"
    ) {
      return "Up Next";
    } else if (
      location.pathname == "/landingpage/mentor" ||
      location.pathname == "/landingpage/mentor/"
    ) {
      return "Up Next";
    } else if (
      location.pathname == "/landingpage/expert" ||
      location.pathname == "/landingpage/expert/"
    ) {
      return "Up Next";
    } else if (
      location.pathname == "/landingpage/partnership" ||
      location.pathname == "/landingpage/partnership/"
    ) {
      return "Up Next";
    } else {
      return "Go Back";
    }
  };
  const getNextLink = () => {
    if (
      location.pathname == "/landingpage" ||
      location.pathname == "/landingpage/" ||
      location.pathname == "/"
    ) {
      return "/landingpage/startup";
    } else if (
      location.pathname == "/landingpage/startup" ||
      location.pathname == "/landingpage/startup/"
    ) {
      return "/landingpage/investor";
    } else if (
      location.pathname == "/landingpage/investor" ||
      location.pathname == "/landingpage/investor/"
    ) {
      return "/landingpage/mentor";
    } else if (
      location.pathname == "/landingpage/mentor" ||
      location.pathname == "/landingpage/mentor/"
    ) {
      return "/landingpage/expert";
    } else if (
      location.pathname == "/landingpage/expert" ||
      location.pathname == "/landingpage/expert/"
    ) {
      return "/landingpage/partnership";
    } else if (
      location.pathname == "/landingpage/partnership" ||
      location.pathname == "/landingpage/partnership/"
    ) {
      return "/aboutus";
    } else {
      return "/landingpage";
    }
  };
  return (
    <React.Fragment>
      <Box className="bg-1">
        <Grid
          direction="row"
          container
          sx={{
            width: "100%",
            // height: "max-content",
          }}
        >
          <Grid item sm={5} xs={12}>
            {" "}
            <Stack
              sx={{
                height: { sm: "100%", xs: "150px" },
                background: "#ACFF8E",
                p: { sm: 10, xs: 3 },
              }}
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              <Typography
                variant="h2"
                className="logo"
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  color: "#2D3558",
                }}
                onClick={() => {
                  navigate(`/`);
                }}
              >
                Prakrti
              </Typography>
              <Typography
                variant="h5"
                // className="logo"
                fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  pl: "63px",
                  color: "#2D3558",
                }}
                onClick={() => {
                  navigate(`/`);
                }}
              >
                Better People. Better Planet.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={7}>
            {" "}
            <Box
              sx={{
                height: "100%",
                background: "#194350",
                py: { sm: 10, xs: 3 },
                px: { sm: 10, xs: 3 },
                position: "relative",
              }}
            >
              {/* <Typography
                color="secondary"
                fontSize="11px"
                data-aos="fade-up"
                data-aos-duration="1000"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                }}
                onClick={(e) => {
                  window.open(`https://bharg.com/`, "_blank");
                }}
              >
                A Bharg Technologies GmbH Company
              </Typography> */}
              <Stack
                direction={{ sm: "row", xs: "column" }}
                spacing={{ sm: 20, xs: 5 }}
              >
                <Stack direction="column" spacing={{ sm: 3, xs: 1 }}>
                  <Typography
                    color="secondary"
                    fontSize="24px"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/landingpage/startup`);
                    }}
                  >
                    Startups
                  </Typography>
                  <Typography
                    color="secondary"
                    fontSize="24px"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/landingpage/investor`);
                    }}
                  >
                    Investors
                  </Typography>
                  <Typography
                    color="secondary"
                    fontSize="24px"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/landingpage/mentor`);
                    }}
                  >
                    Mentors
                  </Typography>
                  <Typography
                    color="secondary"
                    fontSize="24px"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/landingpage/expert`);
                    }}
                  >
                    Experts
                  </Typography>
                  <Typography
                    color="secondary"
                    fontSize="24px"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/landingpage/partnership`);
                    }}
                  >
                    Partners
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={2} divider={<Divider />}>
                  <Stack
                    direction="row"
                    justifyContent={{ sm: "flex-start", xs: "space-between" }}
                    spacing={{ sm: 15, xs: 10 }}
                    sx={{
                      pb: { sm: 9, xs: 3 },
                    }}
                  >
                    <Stack direction="column" spacing={2}>
                      <Typography
                        color="secondary"
                        fontSize="16px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/landingpage`);
                        }}
                      >
                        Home
                      </Typography>
                      <Typography
                        color="secondary"
                        fontSize="16px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/aboutus`);
                        }}
                      >
                        About us
                      </Typography>
                      <Typography
                        color="secondary"
                        fontSize="16px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/blog`);
                        }}
                      >
                        Blog
                      </Typography>
                      <Typography
                        color="secondary"
                        fontSize="16px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/faq`);
                        }}
                      >
                        Faqs
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={2}>
                      <Typography
                        color="secondary"
                        fontSize="18px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        fontWeight={"600"}
                      >
                        Prakrti
                      </Typography>
                      <Stack>
                        <Typography
                          color="secondary"
                          fontSize="18px"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          8834 Schindellegi
                        </Typography>
                        <Typography
                          color="secondary"
                          fontSize="18px"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          Switzerland
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} data-aos="fade-up">
                        <Typography
                          variant="body1"
                          color="secondary"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <AiFillLinkedin size={24} />
                        </Typography>
                        <Typography
                          variant="body1"
                          color="secondary"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <AiFillFacebook size={24} />
                        </Typography>
                        <Typography
                          variant="body1"
                          color="secondary"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <AiFillTwitterSquare size={24} />
                        </Typography>
                        <Typography
                          variant="body1"
                          color="secondary"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <FaInstagramSquare size={24} />
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      pb: { sm: 0, xs: 5 },
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Typography
                        color="secondary"
                        fontSize="13px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/legal-links/privacy-policy`);
                        }}
                      >
                        Legal links
                      </Typography>
                      {/* <Typography
                        color="secondary"
                        fontSize="13px"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/imprint`);
                        }}
                      >
                        Imprint
                      </Typography> */}
                    </Stack>
                    <Typography
                      color="secondary"
                      fontSize="13px"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      © 2022 Prakrti
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            height: { sm: "300px", xs: "150px" },
            backgroundImage: `url("/assets/footer.jpeg")`,
            backgroundPosition: { xs: "center", sm: "unset" },
            backgroundSize: "cover",
            // px: { sm: 10, xs: 3 },
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            {" "}
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{
                width: { sm: "250px", xs: "200px" },
                // px: { sm: 3, xs: 3 },
                py: 3,
                background: "#E9E7E7",
                cursor: "pointer",
                height: "100%",
              }}
              onClick={() => {
                navigate(`${getNextLink()}`);
              }}
            >
              <Stack
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent={"space-between"}
                sx={{
                  width: "100%",
                }}
              >
                <Stack>
                  <Typography
                    fontSize={{ sm: "16px", xs: "16px" }}
                    className="dark-8"
                  >
                    {getNext()}
                  </Typography>
                  <Typography
                    className="dark-8"
                    fontWeight="600"
                    fontSize={{ sm: "28px", xs: "18px" }}
                  >
                    {getNextTitle()}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{
                    height: "50px",
                    width: "70px",
                    border: "1.5px solid #262736",
                    cursor: "pointer",
                  }}
                >
                  {/* <Typography fontSize={{ sm: "36px" }} className="dark-8">
                    <ArrowRightAltIcon fontSize={"30px"} />
                  </Typography> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0.25 0.25 13.5 13.5"
                    height={24}
                    width={24}
                    strokeWidth={1}
                  >
                    <g>
                      <line
                        x1={0.5}
                        y1={7}
                        x2={13.5}
                        y2={7}
                        fill="none"
                        stroke="#262736"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <polyline
                        points="10 10.5 13.5 7 10 3.5"
                        fill="none"
                        stroke="#262736"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Index;
