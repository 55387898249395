import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "bookmarksInvestor";
const initialState = {
  bookmarksInvestor: [],
  loading: false,
  error: null,
};

export const bookmarksInvestorAPI = createAsyncThunk(
  "bookmarks/investor",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/companies?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`
    );
    return data.data;
  }
);

const bookmarksInvestorSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksInvestorAPI.pending, (state) => {
      state.bookmarksInvestor = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(bookmarksInvestorAPI.fulfilled, (state, action) => {
      state.bookmarksInvestor = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bookmarksInvestorAPI.rejected, (state, action) => {
      state.bookmarksInvestor = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default bookmarksInvestorSlice.reducer;
