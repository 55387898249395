import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import axiosApi from "../../../utils/httpClient";
const name = "signin";
const initialState = {
  data: {},
  loading: false,
  message: "",
  error: null,
  success: false,
};

export const signinAPI = createAsyncThunk(
  "auth/signin",
  async ({ email, password }) => {
    let data = {};
    data["email"] = email;
    data["password"] = password;
    const response = await axiosApi.post(
      `${process.env.REACT_APP_BASE_URL}auth/login`,
      data
    );
    return response.data;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  console.log("logged out");
});

export const googleAPI = createAsyncThunk("auth/google", async (token) => {
  const response = await axiosApi.post(
    `${process.env.REACT_APP_BASE_URL}auth/google`,
    {
      token: token,
    }
  );
  return response.data;
});

export const googleLogout = createAsyncThunk("auth/googleLogout", async () => {
  console.log("google logged out");
});

const signinSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(signinAPI.pending, (state) => {
      state.loading = true;
      state.message = "Logging In!!! Please wait!";
      state.error = null;
      state.success = false;
    });
    builder.addCase(signinAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = "";
      state.error = null;
      state.success = true;
    });
    builder.addCase(signinAPI.rejected, (state, action) => {
      state.loading = false;
      state.message = "";
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(googleAPI.pending, (state) => {
      state.loading = true;
      state.message = "Logging In!!! Please wait!";
      state.error = null;
      state.success = false;
    });
    builder.addCase(googleAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = "";
      state.error = null;
      state.success = true;
    });
    builder.addCase(googleAPI.rejected, (state, action) => {
      state.loading = false;
      state.message = "";
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.data = {};
      state.loading = false;
      state.message = "";
      state.error = null;
      state.success = false;
    });
    builder.addCase(googleLogout.fulfilled, (state, action) => {
      state.data = {};
      state.loading = false;
      state.message = "";
      state.error = null;
      state.success = false;
    });
  },
});

export default signinSlice.reducer;
