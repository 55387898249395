import React, { useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { selectProject } from "../../../../redux/selectedproject/selectedProjectSlice";
import { useLocation, Link, Outlet, useNavigate } from "react-router-dom";
import { setDashboardProfile } from "../../../../redux/dashboardProfile/dashboardSlice";
const Index = () => {
  const mode = useSelector((store) => store.mode.mode);
  const dimension = useSelector((store) => store.dimension);
  const location = useLocation();
  const navigate = useNavigate();
  const myprojects = useSelector((store) => store.myprojects?.myprojects);
  const dispatch = useDispatch();
  const selectedProject = useSelector((store) => store.selectedProject);
  const dashboardProfile = useSelector(
    (store) => store.dashboardProfile.dashboardProfile
  );
  let tabs;
  if (dashboardProfile == "Jobs") {
    tabs = [
      {
        id: 1,
        name: "Applications Recieved",
        path: "/dashboard/received",
      },
    ];
  } else {
    tabs = [
      {
        id: 1,
        name:
          dashboardProfile == "Jobs"
            ? "Applications Recieved"
            : "Interests Received",
        path: "/dashboard/received",
      },
      {
        id: 2,
        name:
          dashboardProfile == "Investors"
            ? "Applications Sent"
            : "Interests Sent",
        path: "/dashboard/sent",
      },
    ];
  }

  useEffect(() => {
    if (dashboardProfile == "") {
      dispatch(setDashboardProfile("Investors"));
    }
  }, [dashboardProfile]);
  return (
    <React.Fragment>
      <Box mt={dimension.width > 600 ? 15 : 3} mb={10}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <Typography
            color="secondary"
            variant="h4"
            sx={{
              fontWeight: "600",
            }}
          >
            Dashboard
          </Typography>
          <Typography
            color="secondary"
            variant="h4"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <CloseOutlinedIcon />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          alignContent="center"
          sx={{
            mt: 2,
          }}
        >
          <Button
            color={dashboardProfile == "Investors" ? "primary" : "secondary"}
            variant={dashboardProfile == "Investors" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Investors"));
            }}
          >
            Investors
          </Button>
          <Button
            color={dashboardProfile == "Mentors" ? "primary" : "secondary"}
            variant={dashboardProfile == "Mentors" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Mentors"));
            }}
          >
            Mentors
          </Button>
          <Button
            color={dashboardProfile == "Experts" ? "primary" : "secondary"}
            variant={dashboardProfile == "Experts" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Experts"));
            }}
          >
            Experts
          </Button>
          <Button
            color={dashboardProfile == "Jobs" ? "primary" : "secondary"}
            variant={dashboardProfile == "Jobs" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Jobs"));
            }}
          >
            Jobs
          </Button>
          <Button
            color={dashboardProfile == "Events" ? "primary" : "secondary"}
            variant={dashboardProfile == "Events" ? "contained" : "outlined"}
            sx={{
              minWidth: "100px",
            }}
            onClick={() => {
              dispatch(setDashboardProfile("Events"));
            }}
          >
            Events
          </Button>
        </Stack>
        <Box
          mt={5}
          sx={{ width: "100%" }}
          p={dimension.width > 600 && 3}
          className={
            dimension.width > 600 ? (mode ? "dark-bg-1" : "light-bg-1") : ""
          }
        >
          <Stack
            container
            direction="row"
            // justifyContent="space-around"
            alignContent="center"
            alignItems="center"
            spacing={dimension.width <= 600 ? 3 : 7}
            sx={{
              overflowX: dimension.width <= 600 && "scroll",
              overflow: "hidden",
            }}
          >
            {tabs.map((item) => (
              <Box>
                <Link to={item.path}>
                  <Typography
                    className={
                      location.pathname === item.path
                        ? mode
                          ? "dark-0"
                          : "light-0"
                        : mode
                        ? "dark-4"
                        : "light-4"
                    }
                    variant={dimension.width > 600 ? "body1" : "body2"}
                    align="center"
                    mb={1}
                    style={{
                      borderBottom: `${
                        location.pathname == item.path
                          ? "3px solid #eb4d89"
                          : "none"
                      }`,
                    }}
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Stack>

          <Outlet />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Index;
