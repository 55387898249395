import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosApi from "../../../utils/httpClient";

const name = "bookmarksMentor";
const initialState = {
  bookmarksMentor: [],
  loading: false,
  error: null,
};

export const bookmarksMentorAPI = createAsyncThunk(
  "bookmarks/mentor",
  async ({ search, skip, limit, id, type }) => {
    const data = await axiosApi.get(
      `${
        process.env.REACT_APP_BASE_URL
      }bookmark/mentors?skip=${skip}&limit=${limit}${
        search != "" ? `&search=${search}` : ""
      }`
    );
    return data.data;
  }
);

const bookmarksMentorSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookmarksMentorAPI.pending, (state) => {
      state.bookmarksMentor = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(bookmarksMentorAPI.fulfilled, (state, action) => {
      state.bookmarksMentor = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(bookmarksMentorAPI.rejected, (state, action) => {
      state.bookmarksMentor = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default bookmarksMentorSlice.reducer;
