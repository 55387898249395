import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import HeaderNav from "../LandingHeader";
import BottomNav from "../BottomNav";
import { Container, Stack, Box } from "@mui/material";

import { profileAPI } from "../../redux/auth/profile/profileSlice";
import Footer from "../../components/Footer";
function MainLayout() {
  let store = useSelector((store) => store);

  const profile = useSelector((store) => store.profile);

  const signin = useSelector((store) => store.signin);
  const dispatch = useDispatch();
  const [showCookie, setShowCookie] = useState(null);

  return (
    <React.Fragment>
      <Box className="land-font">
        <Stack
          direction="column"
          sx={{
            position: "absolute",
            zIndex: "12",
            width: "100%",
            pb: { sm: 0, xs: 5 },
          }}
        >
          <HeaderNav />
        </Stack>

        <Outlet />
      </Box>
    </React.Fragment>
  );
}
export default MainLayout;
